import React, { FC, useMemo } from 'react';
import {
  ILoginSocialBarProps,
  LoginSocialBarMenuItemProps,
} from '../../../LoginSocialBar.types';
import { OPTION_VALUE_CHOOSE, OPTION_VALUE_LOG_OUT } from '../../../constants';
import {
  BIAction,
  getMembersAreaNavigationEvent,
  MEMBERS_APP_UOU_ENDPOINT,
} from '../../bi/events';
import styles from './LoginSocialBarNativeMenu.scss';

type ILoginSocialBarNativeMenuProps = Pick<
  ILoginSocialBarProps,
  | 'menuItems'
  | 'onLogout'
  | 'currentPrimaryPageHref'
  | 'navigateTo'
  | 'reportBi'
  | 'translations'
> & {
  ariaLabel: string;
  reportBiOnMenuItemClick: (item: LoginSocialBarMenuItemProps) => void;
};

export const LoginSocialBarNativeMenu: FC<ILoginSocialBarNativeMenuProps> = ({
  ariaLabel,
  menuItems,
  currentPrimaryPageHref,
  onLogout,
  navigateTo,
  translations,
  reportBi,
  reportBiOnMenuItemClick,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    const { value } = event.target;

    if (value === OPTION_VALUE_CHOOSE) {
      return;
    }

    const item = menuItems[parseInt(value, 10)];
    const isLogout = value === OPTION_VALUE_LOG_OUT;

    reportBiOnMenuItemClick(
      isLogout ? { label: translations.logOutText!, link: {} } : item,
    );

    if (isLogout) {
      onLogout();
      return;
    }

    const { link, label } = item;
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore TODO: Fix type for getLink after types merge in TB
    navigateTo(link);

    reportBi(
      getMembersAreaNavigationEvent({
        action: BIAction.ItemClick,
        pageName: label,
      }),
      {
        endpoint: MEMBERS_APP_UOU_ENDPOINT,
      },
    );
  };

  const selectedValue = useMemo(() => {
    const selectedMenuItemIndex = menuItems.findIndex(
      ({ link }) => link.href && link.href === currentPrimaryPageHref,
    );
    return selectedMenuItemIndex !== -1
      ? selectedMenuItemIndex
      : OPTION_VALUE_CHOOSE;
  }, [currentPrimaryPageHref, menuItems]);

  return (
    <select
      aria-label={ariaLabel}
      className={styles.root}
      value={selectedValue}
      onChange={handleChange}
    >
      <option value={OPTION_VALUE_CHOOSE}>
        {translations.menuOptionValueText}
      </option>
      {menuItems.map((item, index) => (
        <option key={index} value={index}>
          {item.label}
          {typeof item.displayCount === 'number' && ` (${item.displayCount})`}
        </option>
      ))}
      <option value={OPTION_VALUE_LOG_OUT}>{translations.logOutText}</option>
    </select>
  );
};
