import React, { FC } from 'react';

import AvatarWrapper from '../avatar';
import {
  AvatarWrapperProps,
  ILoginSocialBarProps,
} from '../../../LoginSocialBar.types';
import styles from './LoggedOffSocialBar.scss';

type LoggedOffSocialBarProps = AvatarWrapperProps &
  Pick<ILoginSocialBarProps, 'translations'> & {
    showAvatar: boolean;
    handleLogin: () => void;
    reportBiOnClick: (label: string) => void;
  };

export const LoggedOffSocialBar: FC<LoggedOffSocialBarProps> = ({
  showAvatar,
  handleLogin,
  isLoggedIn,
  avatarUri,
  iconSize,
  defaultAvatarSvgContent,
  displayMode,
  id,
  userName,
  translations,
  reportBiOnClick,
}) => {
  const onClick = React.useCallback(() => {
    reportBiOnClick(translations.loggedOutText!);
    handleLogin();
  }, [translations.loggedOutText, handleLogin, reportBiOnClick]);

  return (
    <button className={styles.login} onClick={onClick}>
      {showAvatar && (
        <AvatarWrapper
          id={id}
          displayMode={displayMode}
          iconSize={iconSize}
          defaultAvatarSvgContent={defaultAvatarSvgContent}
          isLoggedIn={isLoggedIn}
          userName={userName}
          avatarUri={avatarUri}
        />
      )}
      <span className={styles.logOutText}>{translations.loggedOutText}</span>
    </button>
  );
};
