import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import type {
  ILoginSocialBarControllerProps,
  ILoginSocialBarMapperProps,
  ILoginSocialBarProps,
  ILoginSocialBarStateValues,
} from '../LoginSocialBar.types';

export default withCompController<
  ILoginSocialBarMapperProps,
  ILoginSocialBarControllerProps,
  ILoginSocialBarProps,
  ILoginSocialBarStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { updateProps } = controllerUtils;
  const {
    currentUrl,
    memberDetails,
    logout,
    reportBi,
    navigateTo,
    promptLogin,
  } = stateValues;
  const {
    compId,
    language,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  let membersData: {
    isLoggedIn?: boolean;
    userName?: string;
    avatarUri?: string;
  } = {
    isLoggedIn: false,
    userName: '',
    avatarUri: '',
  };

  if (
    memberDetails &&
    (!!memberDetails.nickname ||
      !!memberDetails.memberName ||
      !!memberDetails.loginEmail)
  ) {
    membersData.isLoggedIn = true;
    membersData.userName =
      memberDetails.nickname ||
      memberDetails.memberName ||
      memberDetails.loginEmail;
    membersData.avatarUri = memberDetails.imageUrl;
  } else if (
    mapperProps.isLoggedIn &&
    mapperProps.userName &&
    mapperProps.avatarUri
  ) {
    membersData = {};
  }

  const commonAnalyticsClickBiParams = {
    language,
    trackClicksAnalytics,
    element_id: compId,
    elementType: fullNameCompType,
    elementGroup: AnalyticsClicksGroups.Community,
  };

  const reportBiOnClick: ILoginSocialBarControllerProps['reportBiOnClick'] =
    label => {
      const { displayMode } = restMapperProps;

      tryReportAnalyticsClicksBi(reportBi, {
        ...commonAnalyticsClickBiParams,
        elementTitle: label,
        details: { displayMode },
      });
    };

  const reportBiOnMenuItemClick: ILoginSocialBarControllerProps['reportBiOnMenuItemClick'] =
    item => {
      const { label, link, displayCount } = item;

      tryReportAnalyticsClicksBi(reportBi, {
        ...commonAnalyticsClickBiParams,
        value: link.href,
        elementTitle: label,
        details: { displayCount },
      });
    };

  return {
    ...restMapperProps,
    ...membersData,
    reportBiOnClick,
    reportBiOnMenuItemClick,
    onMenuOpen: () => {
      updateProps({
        isMenuOpen: true,
      });
    },
    onMenuClose: () => {
      updateProps({
        isMenuOpen: false,
      });
    },
    reportBi,
    currentPrimaryPageHref: currentUrl,
    navigateTo,
    onLogout: () => {
      logout();
    },
    onLogin: () => {
      promptLogin();
    },
  };
});
