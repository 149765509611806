enum BIAction {
  Hover = 'hover',
  MenuClick = 'menu_click',
  ItemClick = 'item_click',
}

const MEMBERS_APP_UOU_ENDPOINT = 'members-app-uou';

const getMembersAreaNavigationEvent = (options: {
  action: BIAction;
  pageName?: string;
}) => ({
  src: 69,
  evid: 866,
  component: 'login-bar',
  ...options,
});

export { getMembersAreaNavigationEvent, BIAction, MEMBERS_APP_UOU_ENDPOINT };
