import React, { FC, KeyboardEventHandler, MouseEvent, useRef } from 'react';
import classNames from 'clsx';
import { keyCodes } from '@wix/editor-elements-common-utils';

import {
  AvatarWrapperProps,
  ILoginSocialBarCustomMenu,
  ILoginSocialBarProps,
  Layout,
  LoginSocialBarMenuItemProps,
} from '../../../LoginSocialBar.types';
import {
  ARIA_LABEL_ACCOUNT_MENU,
  ARIA_LABEL_MEMBERS_BAR,
  DIRECTION_LEFT_TO_RIGHT,
  DIRECTION_RIGHT_TO_LEFT,
} from '../../../constants';

import AvatarWrapper from '../avatar';
import LoginSocialBarCustomMenu from '../loginSocialBarCustomMenu/LoginSocialBarCustomMenu';
import LoginSocialBarIcons from '../loginSocialBarIcons';
import LoginSocialBarNativeMenu from '../loginSocialBarNativeMenu';
import Arrow from '../arrow';
import styles from './LoggedInSocialBar.scss';

type LoggedInSocialBarProps = AvatarWrapperProps &
  Pick<
    ILoginSocialBarProps,
    | 'translations'
    | 'isMenuOpen'
    | 'onMenuOpen'
    | 'onMenuClose'
    | 'menuItems'
    | 'logOutText'
    | 'onLogout'
    | 'currentPrimaryPageHref'
    | 'iconItems'
    | 'direction'
    | 'useNativeMenu'
    | 'navigateTo'
    | 'loggedInText'
    | 'arrowShape'
    | 'menuItemsAlignment'
    | 'reportBi'
  > & {
    showAvatar: boolean;
    showIcons: boolean;
    reportBiOnClick: (label: string) => void;
    reportBiOnMenuItemClick: (item: LoginSocialBarMenuItemProps) => void;
  };

const showUserName = (displayMode: Layout) =>
  displayMode === Layout.TextOnly || displayMode === Layout.AvatarAndText;

export const LoggedInSocialBar: FC<LoggedInSocialBarProps> = ({
  translations,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  menuItems,
  onLogout,
  currentPrimaryPageHref,
  id,
  iconItems,
  direction,
  userName,
  showAvatar,
  useNativeMenu,
  showIcons,
  avatarUri,
  iconSize,
  defaultAvatarSvgContent,
  displayMode,
  isLoggedIn,
  navigateTo,
  loggedInText,
  arrowShape,
  menuItemsAlignment,
  reportBi,
  reportBiOnClick,
  reportBiOnMenuItemClick,
}) => {
  const toggleMenuOpen = () => (isMenuOpen ? onMenuClose() : onMenuOpen());

  const handleLogout = (event: MouseEvent) => onLogout(event);

  const userButtonRef = useRef<HTMLButtonElement>(null);
  const customMenuRef = useRef<ILoginSocialBarCustomMenu>(null);

  const handleUserButtonKeyDown: KeyboardEventHandler = event => {
    const { keyCode } = event;
    const { enter, space, escape, arrowUp, arrowDown } = keyCodes;
    const openMenuKeys: Array<number> = [enter, space, arrowDown];
    const closeMenuKeys: Array<number> = [enter, space, escape, arrowUp];

    if (isMenuOpen) {
      if (closeMenuKeys.includes(keyCode)) {
        event.preventDefault();
        onMenuClose();
      } else if (keyCode === arrowDown) {
        event.preventDefault();
        if (customMenuRef.current) {
          customMenuRef.current.focusFirstMenuItem();
        }
      }
    } else if (openMenuKeys.includes(keyCode)) {
      event.preventDefault();
      onMenuOpen();
    }
  };

  const handleCustomMenuClose = () => {
    onMenuClose();
    if (userButtonRef.current) {
      userButtonRef.current.focus();
    }
  };

  const handleButtonClick = () => {
    reportBiOnClick(loggedInText);
    !useNativeMenu && toggleMenuOpen();
  };

  const commonMenuProps = {
    menuItems,
    translations,
    currentPrimaryPageHref,
    reportBiOnMenuItemClick,
    onLogout: handleLogout,
  };

  const wrapperClassNames = classNames(styles.wrapper, {
    [styles.rtl]: direction === DIRECTION_RIGHT_TO_LEFT,
    [styles.ltr]: direction === DIRECTION_LEFT_TO_RIGHT,
    [styles.withIcons]: showIcons,
  });

  return (
    <div className={wrapperClassNames}>
      {showIcons && (
        <LoginSocialBarIcons
          containerId={id}
          iconItems={iconItems}
          direction={direction}
        />
      )}
      <button
        ref={userButtonRef}
        className={styles.user}
        aria-label={`${userName} ${ARIA_LABEL_ACCOUNT_MENU}`}
        onClick={handleButtonClick}
        onKeyDown={handleUserButtonKeyDown}
      >
        {showAvatar && (
          <AvatarWrapper
            id={id}
            displayMode={displayMode}
            iconSize={iconSize}
            defaultAvatarSvgContent={defaultAvatarSvgContent}
            isLoggedIn={isLoggedIn}
            userName={userName}
            avatarUri={avatarUri}
          />
        )}
        {showUserName(displayMode) && (
          <div className={styles.name}>
            {loggedInText} {userName}
          </div>
        )}
        <Arrow className={styles.arrow} shape={arrowShape} />
        {useNativeMenu && (
          <LoginSocialBarNativeMenu
            {...commonMenuProps}
            ariaLabel={ARIA_LABEL_MEMBERS_BAR}
            navigateTo={navigateTo}
            reportBi={reportBi}
          />
        )}
      </button>
      {!useNativeMenu && (
        <LoginSocialBarCustomMenu
          {...commonMenuProps}
          ref={customMenuRef}
          ariaLabel={ARIA_LABEL_MEMBERS_BAR}
          open={isMenuOpen}
          direction={direction}
          onClose={handleCustomMenuClose}
          menuItemsAlignment={menuItemsAlignment}
          reportBi={reportBi}
        />
      )}
    </div>
  );
};
