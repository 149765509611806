import React, { FC } from 'react';
import classNames from 'clsx';

import {
  AvatarState,
  AvatarWrapperProps,
  Layout,
} from '../../../LoginSocialBar.types';
import styles from './Avatar.scss';
import { Avatar } from './Avatar';

// TODO - Reuse Icon Component
export const AvatarWrapper: FC<AvatarWrapperProps> = ({
  id,
  isLoggedIn,
  avatarUri,
  iconSize,
  defaultAvatarSvgContent,
  userName,
  displayMode,
}) => {
  const isMemberInitials = displayMode === Layout.MemberInitials && isLoggedIn;
  const imageOrVector =
    avatarUri && isLoggedIn ? AvatarState.Image : AvatarState.Vector;
  const avatarState =
    isMemberInitials && isLoggedIn ? AvatarState.Initials : imageOrVector;
  const isPlaceholder = avatarState === AvatarState.Vector;

  return (
    <div
      className={classNames(styles.avatar, {
        [styles.withBorder]: !isPlaceholder,
      })}
    >
      <Avatar
        defaultAvatarSvgContent={defaultAvatarSvgContent}
        size={iconSize}
        name={userName}
        id={id}
        url={avatarUri}
        state={avatarState}
        className={classNames({
          [styles.memberInitials]: isMemberInitials,
          [styles.avatarPlaceholder]: isPlaceholder,
        })}
      />
    </div>
  );
};
